import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import ContactFooter from "../components/ContactForm/ContactFooter"

import mainStyle, {
  sectionStyle,
  mainTextStyle,
  headerStyle,
} from "../components/styles/mainStyle"

const UePluginPage = () => {
  const {
    prismicBasicInfo: {
      data: { orange, main_color_two, main_color_three },
    },
  } = useStaticQuery(graphql`
    query uePlugin {
      prismicBasicInfo {
        data {
          orange
          main_color_two
          main_color_three
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Utrechtse Euro WordPress WooCommerce betalingsplugin"
        description="Accepteer Utrechtse Euro betalingen in uw WordPress WooCommerce webshop."
      />

      <main
        className="borderradius-div-bottom"
        style={{ ...mainStyle, backgroundColor: main_color_two }}
      >
        <section style={sectionStyle}>
          <h1 className="resp-header" style={headerStyle}>
            Utrechtse Euro WordPress WooCommerce betalingsplugin
          </h1>

          <section id="uitgebreide-text" style={mainTextStyle}>
            <p>
              Maak nu gratis gebruik van deze WooCommerce plugin voor WordPress!
            </p>

            <h3>Hoe werkt het?</h3>
            <ol>
              <li>
                <a href="/woocommerce-ue-payment.zip" download>
                  Download
                </a>{" "}
                de plugin
              </li>
              <li>Installeer en activeer de plugin in WordPress</li>
              <li>Activeer de plugin in WooCommerce</li>
              <li>Vul uw U€ gebruikersnaam en wachtwoord in</li>
              <li>
                Optioneel maar zeer geadviseerd: Vraag een AccessClient token
                aan
              </li>
              <li>
                U bent nu klaar om betalingen met U€ te ontvangen op uw website
              </li>
            </ol>

            <p
              style={{
                paddingTop: "2.2em",
                textAlign: "center",
                fontSize: "1.2em",
                lineHeight: "1.8em",
              }}
            >
              Heeft u hulp nodig bij het installeren? WordPress maatwerk nodig?
              Of wilt u uw website naar een hoger niveau brengen?
              <br />
              Neem dan nu snel contact op!
            </p>
          </section>

          <div style={{ textAlign: "center" }}>
            <a
              href="/woocommerce-ue-payment.zip"
              style={{
                display: "inline-block",
                color: main_color_three,
                textDecoration: "none",
                backgroundColor: orange,
                padding: 15,
                margin: "25px 0 75px",
                fontWeight: "bold",
                borderRadius: "5px 25px 5px 5px",
              }}
              download
            >
              Download plugin
            </a>
          </div>
        </section>
      </main>

      <ContactFooter />
    </>
  )
}

export default UePluginPage
